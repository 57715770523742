





import { Component, Vue } from 'vue-property-decorator'

// store
import MasterEducationModule from '@/store/modules/master/education'
import MasterExercisesModule from '@/store/modules/master/exercises'

@Component
export default class GoToMasterExerciseItem extends Vue {
  private get exerciseUUID() {
    return this.$route.params.exerciseUUID
  }

  private get groupID() {
    return this.$route.params.groupID
  }

  private get taskUUID() {
    return this.$route.query.taskUUID
  }

  private mounted() {
    MasterEducationModule.setCurrentMasterGroupID(+this.groupID)
    MasterExercisesModule.clearExerciseTask()
    MasterExercisesModule.clearExercise()
    this.taskUUID ?
      this.$router.replace({ name: 'master.exercises.item.task', params: { exerciseUUID: this.exerciseUUID, groupID: this.groupID, taskUUID: this.taskUUID as string } })
        .then(() => this.$router.replace({ name: 'master.exercises.item.task.messages' })).catch(() => {return}) :
      this.$router.replace({ name: 'master.exercises.item', params: { exerciseUUID: this.exerciseUUID, groupID: this.groupID } }).catch(() => {return})
  }
}
